import React, { useState, useEffect, useRef } from 'react';
import WorkflowsOrders from './WorkflowsOrders';
import Changelog from './Changelog';

const Workflows = () => {
    const [workflowStatus, setWorkflowStatus] = useState({});
    const [duplicateSKUs, setDuplicateSKUs] = useState([]);
    const [noDuplicatesMessage, setNoDuplicatesMessage] = useState('');
    const [activeButton, setActiveButton] = useState(null); // Track the active (pressed) button
    const workflows = {
        RO: '/Him/ReOrder/',
        RI: '/Him/RefreshINV/',
        DD: '/Him/Delete-duplicates/',
        CD: '/Him/Check-duplicates/',
        RA: '/Him/RefreshAssets/',
        PR: '/Him/RefreshPricing/',
        TR: '/Him/RefreshTags/'
    };
    const isMounted = useRef(false);

    const workflowLabels = {
        RO: 'Re-Order Variants',
        RI: 'Run Inventory',
        DD: 'Delete Duplicates',
        CD: 'Check Duplicates',
        RA: 'Refresh Assets',
        PR: 'Run Pricing',
        TR: 'Run Tags'
    };

    const checkWorkflowStatus = async (key) => {
        if (!isMounted.current) return;

        try {
            const response = await fetch(`https://dev.kidcentralsupply.ca/Him/check/${key}`);
            const data = await response.json();

            if (isMounted.current) {
                setWorkflowStatus((prevStatus) => ({
                    ...prevStatus,
                    [key]: data
                }));

                if (key === 'CD') {
                    if (data.duplicate_skus) {
                        setDuplicateSKUs(data.duplicate_skus);
                        setNoDuplicatesMessage('');
                    } else if (data.message === 'No duplicate SKUs found.') {
                        setDuplicateSKUs([]);
                        setNoDuplicatesMessage(data.message);
                    }
                }
            }
        } catch (error) {
            console.error(`Error fetching status for ${key}:`, error);
        }
    };

    const startCountdown = (key) => {
        const intervalId = setInterval(() => {
            if (!isMounted.current) return;
            setWorkflowStatus((prevStatus) => {
                const newWaitTime = Math.max(Math.floor(prevStatus[key]?.wait_time_seconds || 0), 0);
                return {
                    ...prevStatus,
                    [key]: {
                        ...prevStatus[key],
                        wait_time_seconds: newWaitTime,
                    }
                };
            });
        }, 1000);
        return intervalId;
    };

    useEffect(() => {
        isMounted.current = true;

        const intervalIds = Object.keys(workflows).map((key) => {
            checkWorkflowStatus(key);
            return setInterval(() => checkWorkflowStatus(key), 10000);
        });

        Object.keys(workflows).forEach((key) => {
            checkWorkflowStatus(key).then(() => {
                if (workflowStatus[key]?.status === 'busy') {
                    startCountdown(key);
                }
            });
        });

        return () => {
            isMounted.current = false;
            intervalIds.forEach(clearInterval);
        };
    }, []);

    useEffect(() => {
        if (duplicateSKUs.length > 0) {
            console.log('Duplicate SKUs:', duplicateSKUs);
        }
    }, [duplicateSKUs]);

    const handleWorkflowAction = async (endpoint) => {
        try {
            setActiveButton(endpoint); // Set active button when clicked
            const response = await fetch(`https://dev.kidcentralsupply.ca${endpoint}`, {
                method: 'GET',
            });

            if (response.ok) {
                const data = await response.json(); // Parse the response as JSON
                console.log('Response Data:', data); // Log the JSON object
                if (data.duplicate_skus) {
                    setDuplicateSKUs(data.duplicate_skus); // Update the duplicate SKUs
                    setNoDuplicatesMessage('');
                } else if (data.message === 'No duplicate SKUs found.') {
                    setDuplicateSKUs([]);
                    setNoDuplicatesMessage(data.message);
                }
                // Re-check the status after executing the action
                Object.keys(workflows).forEach((key) => {
                    checkWorkflowStatus(key);
                });
            } else {
                console.error('Failed to execute action.');
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setTimeout(() => setActiveButton(null), 200); // Reset active button after a delay
        }
    };

    // Inline styles for modern carbon fiber look buttons
    const buttonStyle = {
        backgroundColor: '#333',
        backgroundImage: 'linear-gradient(45deg, #444 25%, transparent 25%, transparent 75%, #444 75%, #444), linear-gradient(45deg, #444 25%, #333 25%, #333 75%, #444 75%, #444)',
        backgroundSize: '10px 10px',
        backgroundPosition: '0 0, 5px 5px',
        color: '#FFF',
        border: '2px solid #000',
        borderRadius: '8px',
        padding: '12px 24px',
        fontFamily: 'Arial, sans-serif',
        fontWeight: 'bold',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.3)',
        cursor: 'pointer',
        margin: '10px',
        textTransform: 'uppercase',
        transition: 'transform 0.1s, box-shadow 0.1s',
        width: '100%',
        textAlign: 'center'
    };

    const activeButtonStyle = {
        ...buttonStyle,
        transform: 'scale(0.95)', // Slightly shrink the button
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)' // Reduce shadow to simulate pressed effect
    };

    const disabledButtonStyle = {
        ...buttonStyle,
        backgroundColor: '#666',
        backgroundImage: 'none',
        color: '#aaa',
        cursor: 'not-allowed',
        boxShadow: 'none'
    };

    return (
        <div className='wrapper'>
            <h1>Workflows</h1>
            <div style={{ display: 'flex', flexWrap: 'wrap', maxWidth: '100%', justifyContent: 'space-between' }}>
                {Object.entries(workflows).map(([key, endpoint]) => (
                    <div key={key} style={{ flex: '0 1 calc(25% - 20px)', marginBottom: '10px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <button
                                style={
                                    workflowStatus[key]?.status === 'ready'
                                        ? activeButton === endpoint
                                            ? activeButtonStyle
                                            : buttonStyle
                                        : disabledButtonStyle
                                }
                                onClick={() => handleWorkflowAction(endpoint)}
                                disabled={workflowStatus[key]?.status !== 'ready'}
                                title={
                                    workflowStatus[key]?.status === 'busy'
                                        ? `Wait time: ${workflowStatus[key]?.wait_time_seconds} seconds`
                                        : ''
                                }
                            >
                                {workflowLabels[key]}
                            </button>
                        </div>
                    </div>
                ))}
            </div>

            {duplicateSKUs.length > 0 ? (
                <div>
                    <h2>Duplicate SKUs</h2>
                    <ul>
                        {duplicateSKUs.map((sku, index) => (
                            <li key={index}>{sku}</li>
                        ))}
                    </ul>
                </div>
            ) : (
                noDuplicatesMessage && <p>{noDuplicatesMessage}</p>
            )}
            <WorkflowsOrders />
            <Changelog />
        </div>
    );
};

export default Workflows;
