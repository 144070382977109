import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const Changelog = () => {
    const [data, setData] = useState([]);
    const [dateInput, setDateInput] = useState('');
    const [selectedSKU, setSelectedSKU] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    // Pagination states
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);

    useEffect(() => {
        if (dateInput) {
            fetchData(dateInput);
        }
    }, [dateInput]);

    // Reset current page when new data loads.
    useEffect(() => {
        setCurrentPage(1);
    }, [data]);

    const fetchData = async (date) => {
        try {
            const response = await fetch(`https://dev.kidcentralsupply.ca/Him/bluelink-changes?from_date=${date}`);
            const result = await response.json();
            setData(result.changes);
        } catch (error) {
            console.error("Error fetching data", error);
        }
    };

    const openModal = (skuData) => {
        setSelectedSKU(skuData);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setSelectedSKU(null);
        setModalIsOpen(false);
    };

    // --- CSV Export Functions ---
    // Convert JSON array to CSV string with side-by-side columns for each changed field,
    // plus additional columns for the remaining JSON data (OldRowData and NewRowData).
    const convertToCSV = (objArray) => {
        // CSV header with additional columns for OldRowData and NewRowData.
        const header = [
            "SKU",
            "SourceAction",
            "CreatedBy",
            "Timestamp",
            "Changed Field",
            "Old Value",
            "New Value",
            "OldRowData",
            "NewRowData"
        ];
        let csv = header.join(",") + "\r\n";

        objArray.forEach(item => {
            // Get changed fields entries.
            const changedEntries = Object.entries(item.ChangedFields);
            if (changedEntries.length === 0) {
                // If no changed fields, output one row with blanks for changed field columns.
                const row = [
                    item.SKU,
                    item.SourceAction,
                    item.CreatedBy,
                    item.Timestamp,
                    "",
                    "",
                    "",
                    item.OldRowData,
                    item.NewRowData
                ].map(value => `"${value}"`).join(",");
                csv += row + "\r\n";
            } else {
                // For each changed field, output one row.
                changedEntries.forEach(([field, change]) => {
                    const row = [
                        item.SKU,
                        item.SourceAction,
                        item.CreatedBy,
                        item.Timestamp,
                        field,
                        change.old,
                        change.new,
                        item.OldRowData,
                        item.NewRowData
                    ].map(value => `"${value}"`).join(",");
                    csv += row + "\r\n";
                });
            }
        });
        return csv;
    };

    // Trigger download of CSV file
    const downloadCSV = () => {
        const csvData = convertToCSV(data);
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", "sku_changes_export.csv");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // --- Pagination Calculations ---
    const totalPages = Math.ceil(data.length / pageSize);
    const indexOfLastItem = currentPage * pageSize;
    const indexOfFirstItem = indexOfLastItem - pageSize;
    const currentData = data.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <div className="changelog-container">
            <h1 className="changelog-header">SKU Changes</h1>

            <div className="changelog-date-input">
                <label htmlFor="dateInput">Select a Date:</label>
                <input
                    id="dateInput"
                    className="changelog-date-input-field"
                    type="date"
                    value={dateInput}
                    onChange={e => setDateInput(e.target.value)}
                />
            </div>

            {/* Export CSV Button */}
            {data.length > 0 && (
                <div className="changelog-export">
                    <button className="changelog-export-button" onClick={downloadCSV}>
                        Export CSV
                    </button>
                </div>
            )}

            <ul className="changelog-sku-list">
                {currentData.map((item, index) => (
                    <li
                        key={index}
                        className="changelog-sku-item"
                        onClick={() => openModal(item)}
                    >
                        {item.SKU}
                    </li>
                ))}
            </ul>

            {data.length > 25 && (
                <div className="changelog-pagination">
                    <div className="changelog-page-size">
                        <label htmlFor="pageSize">Items per page:</label>
                        <select
                            id="pageSize"
                            value={pageSize}
                            onChange={(e) => {
                                setPageSize(Number(e.target.value));
                                setCurrentPage(1);
                            }}
                        >
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                    <div className="changelog-page-controls">
                        <button
                            onClick={() => setCurrentPage(currentPage - 1)}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>
                        <span>
                            Page {currentPage} of {totalPages}
                        </span>
                        <button
                            onClick={() => setCurrentPage(currentPage + 1)}
                            disabled={currentPage === totalPages}
                        >
                            Next
                        </button>
                    </div>
                </div>
            )}

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="SKU Changes Modal"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)'
                    },
                    content: {
                        border: 'none',
                        borderRadius: '8px',
                        padding: '0',
                        inset: '50% auto auto 50%',
                        transform: 'translate(-50%, -50%)'
                    }
                }}
            >
                {selectedSKU && (
                    <div className="changelog-modal">
                        <h2 className="changelog-modal-header">
                            {selectedSKU.SKU} - Change Details
                        </h2>
                        <div className="changelog-createdby">
                            <strong>Created By:</strong> {selectedSKU.CreatedBy}
                        </div>
                        <h3 className="changelog-modal-subheader">Changed Fields:</h3>
                        <ul className="changelog-modal-list">
                            {Object.entries(selectedSKU.ChangedFields).map(([field, change]) => (
                                <li key={field} className="changelog-modal-list-item">
                                    <span className="field-name">{field}</span>
                                    <div className="change-details">
                                        <span className="old-value">Before: {String(change.old)}</span>
                                        <span className="arrow">→</span>
                                        <span className="new-value">After: {String(change.new)}</span>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <button className="changelog-close-button" onClick={closeModal}>
                            Close
                        </button>
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default Changelog;
