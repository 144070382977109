// WorkflowsOrders.js
import React, { useState, useEffect } from 'react';

// Base URL for API endpoints
const BASE_URL = 'https://dev.kidcentralsupply.ca/Him';

// API Service Functions
const api = {
    getOrderCount: async () => {
        const response = await fetch(`${BASE_URL}/OrderCount/`);
        if (!response.ok) throw new Error('Failed to fetch order count');
        return response.json();
    },
    getOrderPending: async () => {
        const response = await fetch(`${BASE_URL}/OrderPending/`);
        if (!response.ok) throw new Error('Failed to fetch pending orders');
        return response.json();
    },
    getOrderDoc: async (orderId) => {
        const response = await fetch(`${BASE_URL}/OrderDoc/${orderId}`);
        if (!response.ok) throw new Error(`Failed to fetch order doc for ${orderId}`);
        return response.json();
    },
    updateDoc: async (orderId, updatedJson) => {
        const response = await fetch(`${BASE_URL}/update_Doc/${orderId}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(updatedJson),
        });
        if (!response.ok) throw new Error(`Failed to update doc for ${orderId}`);
        return response.json();
    },
    getPreviewBL: async (orderId) => {
        const response = await fetch(`${BASE_URL}/PreviewBL/${orderId}`);
        if (!response.ok) throw new Error(`Failed to fetch Preview BL for ${orderId}`);
        return response.json();
    },
    retryOrder: async (orderId) => {
        const response = await fetch(`${BASE_URL}/RetryOrder/${orderId}`);
        if (!response.ok) throw new Error(`Failed to retry order ${orderId}`);
        return response.json();
    },
    checkError: async (transNum) => {
        const response = await fetch(`${BASE_URL}/CheckError/${transNum}`);
        if (!response.ok) throw new Error(`Failed to check error for transaction ${transNum}`);
        return response.json();
    },
    getGiveItToMeRaw: async (customerCode) => {
        let url = `${BASE_URL}/GiveItToMeRaw/${encodeURIComponent(customerCode)}`;
        console.log(`Fetching Raw Orders from URL: ${url}`);
        try {
            const response = await fetch(url);
            if (!response.ok) {
                console.error(`Error fetching raw orders: ${response.status} ${response.statusText}`);
                throw new Error(`Failed to fetch raw orders: ${response.status} ${response.statusText}`);
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Fetch Error:', error);
            throw error;
        }
    },

    getShowSplits: async (rawOrderId) => {
        const response = await fetch(`${BASE_URL}/showSplits/${rawOrderId}`);
        if (!response.ok) throw new Error('Failed to fetch splits');
        return response.json();
    },
};

// Inline Styles
const styles = {
    appContainer: {
        fontFamily: `'Helvetica Neue', Helvetica, Arial, sans-serif`,
        padding: '20px',
        backgroundColor: '#f5f7fa',
    },
    tabs: {
        display: 'flex',
        backgroundColor: '#ffffff',
        marginBottom: '20px',
        borderRadius: '8px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
        overflow: 'hidden',
    },
    tabButton: {
        padding: '14px 20px',
        border: 'none',
        background: 'none',
        cursor: 'pointer',
        flex: 1,
        textAlign: 'center',
        fontSize: '16px',
        color: '#555555',
        transition: 'background-color 0.3s, color 0.3s',
    },
    activeTabButton: {
        padding: '14px 20px',
        border: 'none',
        backgroundColor: '#007BFF',
        color: '#ffffff',
        cursor: 'pointer',
        flex: 1,
        textAlign: 'center',
        fontSize: '16px',
        transition: 'background-color 0.3s, color 0.3s',
    },
    tabContent: {
        padding: '20px',
        backgroundColor: '#ffffff',
        borderRadius: '8px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    },
    modalOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
        padding: '10px',
    },
    modal: {
        backgroundColor: '#ffffff',
        padding: '30px',
        width: '90%',
        maxWidth: '600px',
        maxHeight: '90%',
        overflowY: 'auto',
        position: 'relative',
        borderRadius: '8px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
        transition: 'transform 0.3s ease-out, opacity 0.3s ease-out',
    },
    closeButton: {
        position: 'absolute',
        top: '15px',
        right: '20px',
        border: 'none',
        background: 'none',
        fontSize: '24px',
        cursor: 'pointer',
        color: '#333333',
    },
    preformatted: {
        backgroundColor: '#f0f4f8',
        padding: '15px',
        borderRadius: '6px',
        textAlign: 'left',
        overflowX: 'auto',
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
        fontFamily: `'Courier New', Courier, monospace`,
        fontSize: '14px',
    },
    errorSection: {
        backgroundColor: '#ffe6e6',
        padding: '15px',
        borderRadius: '6px',
        marginTop: '20px',
    },
    actions: {
        marginTop: '20px',
        display: 'flex',
        gap: '10px',
        flexWrap: 'wrap',
    },
    actionButton: {
        padding: '10px 16px',
        border: 'none',
        backgroundColor: '#007BFF',
        color: '#ffffff',
        borderRadius: '4px',
        cursor: 'pointer',
        fontSize: '14px',
        transition: 'background-color 0.3s',
    },
    linkButton: {
        padding: '0',
        border: 'none',
        background: 'none',
        color: '#007BFF',
        textDecoration: 'underline',
        cursor: 'pointer',
        fontSize: '16px',
        transition: 'color 0.3s',
    },
    error: {
        color: '#dc3545',
        marginTop: '10px',
    },
    success: {
        color: '#28a745',
        marginTop: '10px',
    },
    filterSection: {
        marginBottom: '20px',
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        flexWrap: 'wrap',
    },
    input: {
        padding: '10px',
        fontSize: '16px',
        borderRadius: '4px',
        border: '1px solid #ccc',
        minWidth: '200px',
        flex: '1',
    },
    textarea: {
        width: '100%',
        padding: '10px',
        fontSize: '14px',
        borderRadius: '4px',
        border: '1px solid #ccc',
        resize: 'vertical',
        fontFamily: `'Courier New', Courier, monospace`,
    },
    editSection: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    },
    heading: {
        fontSize: '24px',
        color: '#333333',
        marginBottom: '10px',
    },
    subHeading: {
        fontSize: '18px',
        color: '#333333',
        marginBottom: '8px',
    },
    text: {
        fontSize: '16px',
        color: '#555555',
    },
    list: {
        listStyleType: 'none',
        paddingLeft: '0',
    },
    listItem: {
        padding: '8px 0',
        borderBottom: '1px solid #e0e0e0',
    },
    loading: {
        color: '#555555',
    },
};

// Modal Component
const Modal = ({ children, onClose }) => {
    return (
        <div style={styles.modalOverlay}>
            <div style={styles.modal}>
                <button style={styles.closeButton} onClick={onClose} aria-label="Close Modal">
                    &times;
                </button>
                {children}
            </div>
        </div>
    );
};

// WorkflowsOrders Component
const WorkflowsOrders = () => {
    // State for active tab
    const [activeTab, setActiveTab] = useState('processed');

    return (
        
        <div style={styles.appContainer}>
            <nav style={styles.tabs}>
                <button
                    style={activeTab === 'processed' ? styles.activeTabButton : styles.tabButton}
                    onClick={() => setActiveTab('processed')}
                >
                    Processed Orders
                </button>
                <button
                    style={activeTab === 'pending' ? styles.activeTabButton : styles.tabButton}
                    onClick={() => setActiveTab('pending')}
                >
                    Order Pending
                </button>
                <button
                    style={activeTab === 'raw' ? styles.activeTabButton : styles.tabButton}
                    onClick={() => setActiveTab('raw')}
                >
                    Raw Orders
                </button>
                <button
                    style={activeTab === 'splits' ? styles.activeTabButton : styles.tabButton}
                    onClick={() => setActiveTab('splits')}
                >
                    Show Splits
                </button>
            </nav>
            <div style={styles.tabContent}>
                {activeTab === 'processed' && <ProcessedOrdersTab />}
                {activeTab === 'pending' && <OrderPendingTab />}
                {activeTab === 'raw' && <RawOrdersTab />}
                {activeTab === 'splits' && <ShowSplitsTab />}
            </div>
        </div>
    );
};

// Processed Orders Tab
const ProcessedOrdersTab = () => {
    const [count, setCount] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchCount = async () => {
            try {
                const data = await api.getOrderCount();
                setCount(data.OrdersLast24Hrs || 0); // Corrected key with default
            } catch (err) {
                setError(true);
                console.error(err);
            } finally {
                setLoading(false);
            }
        };
        fetchCount();
    }, []);

    if (loading) return <p style={styles.loading}>Loading...</p>;
    if (error) return <p style={styles.error}>Error fetching order count.</p>;

    return (
        <div>
            <h2 style={styles.heading}>Processed Orders (Last 24 Hours)</h2>
            <p style={styles.text}>Total Processed Orders: <strong>{count}</strong></p>
        </div>
    );
};

// Order Pending Tab
const OrderPendingTab = () => {
    const [pendingOrders, setPendingOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    // Modal state
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const fetchPendingOrders = async () => {
            try {
                const data = await api.getOrderPending();
                console.log('OrderPendingTab API Response:', data); // Debugging line
                setPendingOrders(data.OrdersPending || []); // Corrected key with fallback
            } catch (err) {
                setError(true);
                console.error(err);
            } finally {
                setLoading(false);
            }
        };
        fetchPendingOrders();
    }, []);

    const openModal = (order) => {
        setSelectedOrder(order);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setSelectedOrder(null);
        setIsModalOpen(false);
    };

    if (loading) return <p style={styles.loading}>Loading...</p>;
    if (error) return <p style={styles.error}>Error fetching pending orders.</p>;

    return (
        <div>
            <h2 style={styles.heading}>Pending Orders</h2>
            {pendingOrders.length === 0 ? (
                <p style={styles.text}>No pending orders.</p>
            ) : (
                <ul style={styles.list}>
                    {pendingOrders.map((order) => (
                        <li key={order.id} style={styles.listItem}>
                            <button style={styles.linkButton} onClick={() => openModal(order)}>
                                {order.id}
                            </button>
                        </li>
                    ))}
                </ul>
            )}
            {isModalOpen && selectedOrder && <OrderModal order={selectedOrder} onClose={closeModal} isRawOrder={false} />}
        </div>
    );
};

// Order Modal Component
const OrderModal = ({ order, onClose, isRawOrder }) => {
    const [orderDoc, setOrderDoc] = useState(null);
    const [blJson, setBlJson] = useState(null);
    const [errorDetails, setErrorDetails] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editedJson, setEditedJson] = useState('');
    const [loading, setLoading] = useState(true);
    const [actionLoading, setActionLoading] = useState(false);
    const [actionError, setActionError] = useState(null);
    const [actionSuccess, setActionSuccess] = useState(null);

    useEffect(() => {
        const fetchOrderDetails = async () => {
            try {
                const doc = await api.getOrderDoc(order.id);
                setOrderDoc(doc);
                const bl = await api.getPreviewBL(order.id);
                setBlJson(bl);
                // Check for BL_WebTrans# key
                if (doc['BL_WebTrans#']) {
                    const transNum = doc['BL_WebTrans#'];
                    const error = await api.checkError(transNum);
                    setErrorDetails(error);
                }
            } catch (err) {
                setActionError('Failed to fetch order details.');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };
        fetchOrderDetails();
    }, [order.id]);

    const handleEdit = () => {
        setIsEditing(true);
        setEditedJson(JSON.stringify(orderDoc, null, 2));
    };

    const handleSave = async () => {
        // Confirmation before submit
        const confirmSave = window.confirm('Are you sure you want to submit the updated JSON?');
        if (!confirmSave) return;

        setActionLoading(true);
        setActionError(null);
        setActionSuccess(null);
        try {
            // Validate JSON
            const updated = JSON.parse(editedJson); // This will throw if JSON is invalid
            await api.updateDoc(order.id, updated);
            const refreshedDoc = await api.getOrderDoc(order.id);
            setOrderDoc(refreshedDoc);
            const refreshedBl = await api.getPreviewBL(order.id);
            setBlJson(refreshedBl);
            setIsEditing(false);
            setActionSuccess('Document updated successfully.');
            // Re-check for errors
            if (refreshedDoc['BL_WebTrans#']) {
                const transNum = refreshedDoc['BL_WebTrans#'];
                const error = await api.checkError(transNum);
                setErrorDetails(error);
            } else {
                setErrorDetails(null);
            }
        } catch (err) {
            setActionError('Failed to update document. Ensure JSON is valid.');
            console.error(err);
        } finally {
            setActionLoading(false);
        }
    };

    const handleRetry = async () => {
        setActionLoading(true);
        setActionError(null);
        setActionSuccess(null);
        try {
            await api.retryOrder(order.id);
            setActionSuccess('Retry initiated successfully.');
            // Optionally, refresh the order details
            const refreshedDoc = await api.getOrderDoc(order.id);
            setOrderDoc(refreshedDoc);
            const refreshedBl = await api.getPreviewBL(order.id);
            setBlJson(refreshedBl);
            if (refreshedDoc['BL_WebTrans#']) {
                const transNum = refreshedDoc['BL_WebTrans#'];
                const error = await api.checkError(transNum);
                setErrorDetails(error);
            } else {
                setErrorDetails(null);
            }
        } catch (err) {
            setActionError('Failed to retry the order.');
            console.error(err);
        } finally {
            setActionLoading(false);
        }
    };

    if (loading) return <div style={styles.modalContent}>Loading...</div>;
    if (!orderDoc) return <div style={styles.modalContent}>No order details available.</div>;

    return (
        <Modal onClose={onClose}>
            <h3 style={styles.heading}>Order Details: {order.id}</h3>
            <div style={styles.section}>
                <h4 style={styles.subHeading}>Order Document</h4>
                <pre style={styles.preformatted}>{JSON.stringify(orderDoc, null, 2)}</pre>
            </div>
            <div style={styles.section}>
                <h4 style={styles.subHeading}>BL JSON</h4>
                <pre style={styles.preformatted}>{JSON.stringify(blJson, null, 2)}</pre>
            </div>
            {errorDetails && (
                <div style={styles.errorSection}>
                    <h4 style={styles.subHeading}>Error Details</h4>
                    <pre style={styles.preformatted}>{JSON.stringify(errorDetails, null, 2)}</pre>
                </div>
            )}
            {/* Actions Section */}
            <div style={styles.actions}>
                {/* Show Edit Button */}
                <button style={styles.actionButton} onClick={handleEdit} disabled={isEditing || actionLoading}>
                    Edit JSON
                </button>
                {/* Show Retry Button for Raw Orders */}
                {isRawOrder && (
                    <button style={styles.actionButton} onClick={handleRetry} disabled={actionLoading}>
                        Send to BL
                    </button>
                )}
            </div>
            {/* Edit Section */}
            {isEditing && (
                <div style={styles.editSection}>
                    <textarea
                        style={styles.textarea}
                        rows="10"
                        value={editedJson}
                        onChange={(e) => setEditedJson(e.target.value)}
                    ></textarea>
                    <div>
                        <button style={styles.actionButton} onClick={handleSave} disabled={actionLoading}>
                            Submit
                        </button>
                        <button
                            style={{ ...styles.actionButton, backgroundColor: '#6c757d' }}
                            onClick={() => setIsEditing(false)}
                            disabled={actionLoading}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            )}
            {actionLoading && <p style={styles.loading}>Processing...</p>}
            {actionError && <p style={styles.error}>{actionError}</p>}
            {actionSuccess && <p style={styles.success}>{actionSuccess}</p>}
        </Modal>
    );
};

// Raw Orders Tab
const RawOrdersTab = () => {
    const [rawOrders, setRawOrders] = useState([]);
    const [customerCode, setCustomerCode] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null); // Changed from boolean to object

    // Modal state
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const fetchRawOrders = async () => {
        if (!customerCode.trim()) {
            alert('Please enter a Customer Code.');
            return;
        }
        setLoading(true);
        setError(null); // Reset error
        try {
            const data = await api.getGiveItToMeRaw(customerCode); // Updated endpoint
            const fetchedOrders = data.orders || []; // Updated to match the API response

            setRawOrders(fetchedOrders); // Directly set fetchedOrders
        } catch (err) {
            setError(err);
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const openModal = (order) => {
        setSelectedOrder(order);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setSelectedOrder(null);
        setIsModalOpen(false);
    };

    return (
        <div>
            <h2 style={styles.heading}>Raw Orders</h2>
            <div style={styles.filterSection}>
                <input
                    type="text"
                    placeholder="Customer Code"
                    value={customerCode}
                    onChange={(e) => setCustomerCode(e.target.value)}
                    style={styles.input}
                />
                <button style={styles.actionButton} onClick={fetchRawOrders} disabled={loading}>
                    Fetch Raw Orders
                </button>
            </div>
            {loading && <p style={styles.loading}>Loading...</p>}
            {error && <p style={styles.error}>Error fetching raw orders: {error.message}</p>}
            {!loading && !error && (
                <>
                    {rawOrders.length === 0 ? (
                        <p style={styles.text}>No raw orders found for Customer Code: "{customerCode}".</p>
                    ) : (
                        <ul style={styles.list}>
                            {rawOrders.map((order) => (
                                <li key={order.id} style={styles.listItem}>
                                    <button style={styles.linkButton} onClick={() => openModal(order)}>
                                        {order.id}
                                    </button>{' '}
                                    - {new Date(order.RecDate).toLocaleDateString()}
                                </li>
                            ))}
                        </ul>
                    )}
                    {/* Display Raw JSON Response */}
                    <div style={{ marginTop: '20px' }}>
                        <h3 style={styles.subHeading}>Raw JSON Response:</h3>
                        <pre style={styles.preformatted}>{JSON.stringify(rawOrders, null, 2)}</pre>
                    </div>
                    {isModalOpen && selectedOrder && <OrderModal order={selectedOrder} onClose={closeModal} isRawOrder={true} />}
                </>
            )}
        </div>
    );
};

// Show Splits Tab
const ShowSplitsTab = () => {
    const [splits, setSplits] = useState([]);
    const [rawOrderId, setRawOrderId] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const fetchSplits = async () => {
        if (!rawOrderId.trim()) {
            alert('Please enter a Raw Order ID.');
            return;
        }
        setLoading(true);
        setError(false);
        try {
            const data = await api.getShowSplits(rawOrderId);
            setSplits(data.splits || []); // Adjust based on actual response structure
        } catch (err) {
            setError(true);
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <h2 style={styles.heading}>Show Splits</h2>
            {/* Form to fetch splits by Raw Order ID */}
            <div style={styles.filterSection}>
                <input
                    type="text"
                    placeholder="Raw Order ID"
                    value={rawOrderId}
                    onChange={(e) => setRawOrderId(e.target.value)}
                    style={styles.input}
                />
                <button style={styles.actionButton} onClick={fetchSplits} disabled={loading}>
                    Fetch Splits
                </button>
            </div>
            {loading && <p style={styles.loading}>Loading...</p>}
            {error && <p style={styles.error}>Error fetching splits.</p>}
            {!loading && !error && (
                <>
                    {splits.length === 0 ? (
                        <p style={styles.text}>No splits available.</p>
                    ) : (
                        <ul style={styles.list}>
                            {splits.map((split) => (
                                <li key={split.id} style={styles.listItem}>
                                    {split.orderNumber} - {split.details}
                                </li>
                            ))}
                        </ul>
                    )}
                </>
            )}
        </div>
    );
};

// Export the component
export default WorkflowsOrders;
